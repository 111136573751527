<template>
  <!-- Container -->
  <div>
    <WeContent v-bind:fluid="fluid" v-bind:wide="!fluid && wide">
      <transition appear name="wecontainer-transition" mode="out-in">
        <WeCard
          v-if="card"
          v-bind:header="header"
          v-bind:background="headerBackground"
          v-bind:actions="actions"
          v-bind:bread-crumb="breadCrumb"
          v-bind:title="title"
          v-bind:construction="construction"
        >
          <div v-if="viewName">
            <router-view v-bind:name="viewName"></router-view>
          </div>
          <div v-else>
            <slot></slot>
          </div>
        </WeCard>
        <div v-else>
          <div v-if="viewName">
            <router-view v-bind:name="viewName"></router-view>
          </div>
          <div v-else>
            <slot></slot>
          </div>
        </div>
      </transition>
    </WeContent>
    <!-- <WeSeoBar v-if="seoBar" /> -->
  </div>
  <!-- ./Container -->
</template>

<script>
export default {
  name: "WeContainer",
  props: {
    header: {
      default: "",
    },
    "header-background": {
      default: "true",
    },
    actions: {
      type: Array,
    },
    "bread-crumb": {
      default: "",
    },
    title: {
      default: "",
    },
    fluid: {
      default: "",
    },
    wide: {
      default: "true",
    },
    card: {
      default: "true",
    },
    background: {
      default: "",
    },
    "seo-bar": {
      default: "",
    },
    "view-name": {
      default: "",
    },
    construction: {
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.wecontainer-transition-enter,
.wecontainer-transition-leave-to {
  opacity: 0;
  margin-top: -16px;
}

.wecontainer-transition-enter-active,
.wecontainer-transition-leave-active {
  transition: opacity 500ms ease-in-out, margin-top 350ms ease-in-out;
}
</style>
